
import { defineComponent } from "vue-demi";
import Transaction from "@/components/owner/transaction/index.vue";

export default defineComponent({
  name: "OwnerTransactionView",
  components: {
    Transaction,
  },
});
